<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Contests" subtitle="Take part in contests and win prizes!">

		<app-content-head-actions-item text="Scan" icon="scan" v-on:click="onScanClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-grid="true" :is-empty="!collection.length" placeholder="Sorry, no contests found.">

		<app-card :metrics="metrics(item)" to-name="Convention.Schedule.Contest" imageIcon="contests" :tab="$constants.schedule.contest.statusText[item.status]" :greyscale="item.status === $constants.schedule.contest.status.completed" :to-id="item.id" v-for="item in collection" tooltip="Click to view contest" :key="item.id" :image="item.image" :title="item.name" :subtitle="subtitle(item)" />

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			is: {
				subscribing: false
			},
			pagination: false,
			endpoint: 'convention/schedule/contests',
			live: 'convention/schedule/contests'
		}

	},

	methods: {

		onScanClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Contests.Scan'
			})

		},

		subtitle: function(item) {

			if (item.status === this.$constants.schedule.contest.status.active) {

				return (item.to) ? 'Ends ' + this.$filter('fromNow', item.to) : false

			} else {

				return false

			}

		},

		metrics: function(item) {

			return [
				{
					value: item.count.participants,
					label: 'Participant'
				},
				{
					value: item.count.prizes,
					label: 'Prize'
				}
			]

		}

	}

}

</script>

<style scoped>

</style>